
















import { defineComponent } from "@vue/composition-api";

import GlobeErrorIcon from "@/assets/ElementsImage/GlobeErrorIcon.vue";

export default defineComponent({
  components: {
    GlobeErrorIcon,
  },
});
